
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Maps from "./pages/register/Maps";
import OwnerDetails from "./pages/register/OwnerDetails";
import Inventory from "./pages/Inventory";
import MyAds from "./pages/myads/MyAds";
import PublicPage from "./pages/PublicPage";
import NewAd from "./pages/myads/NewAd";
import MyCalendar from "./pages/calendar/MyCalendar";
import Form from "./pages/Form";
import Landing from "./pages/Landing";
import { Business } from "@material-ui/icons";
import BusinessRegister from "./pages/register/BusinessRegister";
import Register from "./pages/register/Register";
import Terms from "./pages/Terms";
import DeleteUser from "./pages/DeleteUser";
function App() {
  return (
    <div className="App">
      <Router>

        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>

          <Route exact path="/terms">
            <Terms />
          </Route>

          <Route exact path="/form">
            <DeleteUser />
          </Route> 

          {/* <Route exact path="/register">
            <Register />
          </Route>
            
          <Route exact path="/business/register">
            <BusinessRegister />
          </Route>
          <Route exact path="/owner">
            <OwnerDetails />
          </Route>
          <Route exact path="/map">
            <Maps />
          </Route>
          <Route exact path="/inventory">
           <Inventory />
          </Route>
          <Route exact path="/myads">
           <MyAds />
          </Route>
          <Route exact path="/mypage">
            <PublicPage />
          </Route>
          <Route exact path="/new">
            <NewAd />
          </Route>
          <Route exact path="/mycalendar">
            <MyCalendar />
          </Route>
          <Route exact path="/form">
            <Form />
          </Route> */}

       </Switch>

      </Router>
      
    </div>
  );
}

export default App;
