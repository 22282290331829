import React, {useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoOutline from "../assets/logo-with-outline.svg";
import mobile from "../assets/mobile-frame.svg";
import hyperlocal from "../assets/icons/hyperlocal.svg";
import realtime from "../assets/icons/realtime.svg";
import anonymous from "../assets/icons/anonymous.svg";
import responsible from "../assets/icons/responsible.svg";
import download from "../assets/download-apple.png";
import { useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import eventZapDetails from "../assets/event-zap-details.svg";
import qZapDetails from "../assets/qzap-details.svg";
import messageZapDetails from "../assets/message-zap-details.svg";

import eventZap from "../assets/event-zap.svg";
import qZap from "../assets/question-zap.svg";
import messageZap from "../assets/message-zap.svg";

import eventZapMobile from "../assets/event-zap-mobile.svg";
import qZapMobile from "../assets/qzap-mobile.svg";
import messageZapMobile from "../assets/message-zap-mobile.svg";

import localUpdates from "../assets/local-updates.svg";

gsap.registerPlugin(ScrollTrigger);

function Landing() {
  const [tabIndex, settabIndex] = useState(0)
  const [year, setyear] = useState(0)
  const steppersRef = useRef();
  const pinTargetRef = useRef();
  const triggerRef = useRef();
  const contentMarker1 = useRef();
  const contentMarker2 = useRef();
  const heroSectionRef = useRef();
  
  const [color, setcolor] = useState("#FFD740");
  const [trigger0, settrigger0] = useState(false);
  const [trigger1, settrigger1] = useState(false);
  const [trigger2, settrigger2] = useState(false);
  const [src, setsrc] = useState(eventZapDetails);
  const [zapSrc, setZapSrc] = useState(eventZap);


  const handleScroll = () => {
    // const position = window.pageYOffset;
    const contentM1 = contentMarker1.current;
    const contentM2 = contentMarker2.current
    const hero = heroSectionRef.current;




    const trigger1Height = (contentM1.scrollHeight/2)+ hero.scrollHeight;
    const trigger2Height = contentM1.scrollHeight +((contentM2.scrollHeight)/2) + hero.scrollHeight

    if( window.scrollY > trigger1Height && window.scrollY <= trigger2Height){
      settrigger1(true);
      settrigger0(false);
      settrigger2(false);
    } else if (window.scrollY > trigger2Height){
      settrigger1(false);
      settrigger0(false);
      settrigger2(true);
    } else if (window.scrollY > 0 && window.scrollY <= hero.scrollHeight){
      settrigger1(false);
      settrigger0(true);
      settrigger2(false);
    }
    
};

useEffect(()=>{
  const stepper = steppersRef.current;
  if (trigger2){
    setcolor("#7BBDF9");
    setsrc(messageZapDetails)
    setZapSrc(messageZap)
  }

  if (trigger1){
    setcolor('#A07DEE');
    setsrc(qZapDetails)
    setZapSrc(qZap)
  }

  if(trigger0) {
    setcolor("#FFD740")
    setsrc(eventZapDetails)
    setZapSrc(eventZap)
  }
}, [trigger1, trigger2, trigger0])

useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);

  useEffect(() => {
    const d = new Date();
    setyear(d.getFullYear());
  }, [])

  useEffect(() => {
   
    const steppers = steppersRef.current;
    const pinTarget = pinTargetRef.current;
    const triggerTarget = triggerRef.current;
    gsap.timeline({
      scrollTrigger: {
        trigger: triggerTarget,
        pin: pinTarget,
        start: "-50px top",
        end: () => `+=${steppers.offsetHeight - 2 * pinTarget.offsetHeight}`,
        markers: false,
        invalidateOnRefresh: true,
      }});

}, [])


  const url = "https://play.google.com/store/apps/details?id=com.laxman.mapzap";

  return (
    <div className="bg-bg  text-whitefont font-poppins overflow-hidden">
      <div className="">
        <nav className="flex px-8 xl:px-2 items-center justify-between pt-4 max-w-7xl my-0 mx-auto">
          <NavLink
            to="/"
            className="flex items-center gap-4 text-lg justify-start no-underline"
            activeClassName="bg-transparent"
          >
            <img src={logo} alt="MapZap logo" className="h-9" />
            <p to="/" className="bg-transparent text-xl font-medium ">
              MapZap
            </p>
          </NavLink>

          <div className="flex-container">
          <a
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.laxman.mapzap&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
                      "_blank"
                    )
                  }
                >
                  <img
                    className="lg:h-14 h-12 mx-auto"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
                <a
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/in/app/mapzap/id1672644719",
                      "_blank"
                    )
                  }
                >
                  <img
                    className="lg:h-10 h-8 mx-auto"
                    alt="Download on App store"
                    src={download}
                  />
                </a>
          </div>
        </nav>

        <main className="">
          <div ref={heroSectionRef} className="hero-section px-2 lg:px-0 max-w-7xl mx-auto my-auto">
            <div className="m-auto h-full flex flex-col items-center justify-center">
              <h1 className="xl:text-7xl lg:pt-0 text-5xl pt-5 text-center">Sense the pulse of your city like never before!</h1>
              <p className="text-gray-400 xl:text-4xl text-xl text-center">Anonymous, Real time </p>
              <p className="text-gray-400 xl:text-4xl text-xl text-center">Event updates, Q&A, Discussion on favorite topics.</p>
            </div>
          </div>
          <div className=" bg-yellowaccent py-10 hidden lg:block"  style={{backgroundColor: color}} >
            <div ref={triggerRef} className="max-w-7xl main-sub-header px-8 xl:px-2my-0 mx-auto flex w-full justify-between">
              <div ref={steppersRef} className="steppers">
                <div ref={contentMarker1}  data-marker-content="img1" className="ṃain-left-section w-1/2 flex flex-col content-marker event-container">
                  <h1 className="font-semibold text-black text-7xl leading-tight">
                    Event Zaps
                  </h1>
                  <p className="font-medium text-3xl text-gray-800">
                  Follow your local events and enjoy real time updates
                  </p>
                </div>
                <div ref={contentMarker2}  data-marker-content="img2" className="ṃain-left-section w-1/2 flex flex-col content-marker">
                  <h1 className="font-semibold text-black text-7xl leading-tight">
                  Ask location based  questions
                  </h1>

                </div>
                <div  data-marker-content="img3" className="ṃain-left-section w-1/2 flex flex-col content-marker">
                  <h1 className="font-semibold text-black text-7xl leading-tight">
                  Stay connected with local community
                  </h1>

                </div>
              </div>
            <div ref={pinTargetRef} id="pin-target">
              <img className="mobile-base h-full" src={mobile} alt="" />
              <img className="mobile-content" src={src} alt="" />
              <img className="zap-content" src={zapSrc} alt="" />
            </div>
            </div>
          </div>

          <div className=" py-10 block lg:hidden">
            <div className="max-w-7xl main-sub-header w-full">
              <div className="ṃain-left-section py-8 px-8 xl:px-2 my-0 mx-auto bg-yellowaccent flex flex-col content-marker event-container">
                <div className="">
                  <h1 className="font-semibold text-black text-5xl leading-tight">
                    Event Zaps
                  </h1>
                  <p className="font-medium text-xl text-gray-800">
                  Follow your local events and enjoy real time updates
                  </p>
                </div>
                <div>
                  <img className="mobile-base w-3/4 mx-auto mt-8 max-w-xs" src={eventZapMobile} alt="" />
                </div>
              </div>
              <div className="ṃain-left-section py-8 px-8 xl:px-2 my-0 mx-auto bg-blueaccent flex flex-col content-marker event-container">
                <div className="">
                  <h1 className="font-semibold text-black text-5xl leading-tight">
                  Stay connected with local community
                  </h1>
                </div>
                <div>
                  <img className="mobile-base w-3/4 mx-auto mt-8 max-w-xs" src={messageZapMobile} alt="" />
                </div>
              </div>
              <div className="ṃain-left-section py-8 px-8 xl:px-2 my-0 mx-auto bg-purpleaccent flex flex-col content-marker event-container">
                <div className="">
                  <h1 className="font-semibold text-black text-5xl leading-tight">
                  Ask location based questions
                  </h1>
                </div>
                <div>
                  <img className="mobile-base w-3/4 mx-auto mt-8 max-w-xs" src={qZapMobile} alt="" />
                </div>
              </div>
            
            </div>
          </div>

          <div className="max-w-7xl main-sub-header px-8 xl:px-2my-0 mx-auto lg:flex-row flex-col flex w-full justify-between items-center h-screen">
            <div className="left">
              <h1 className="text-5xl pt-8 lg:pt-0 lg:text-7xl text-left">Discover <br /> like-minded buddies around you</h1>
            </div>
            <div className="right">
              <img src={localUpdates} className="py-8 lg:py-0 w-3/4 mx-auto lg:mx-0" alt="" />
            </div>
          </div>
          
          <div className="bg-lightbg">
            <div className="max-w-7xlpx-8 xl:px-2  my-0 mx-auto py-20 w-full">
              <h2 className="font-medium text-3xl text-center">
                Mapzap{" "}
                <span className="text-violetaccent  font-semibold text-center">
                  Principles
                </span>
              </h2>
              <div className="principle flex justify-around items-center">
                <div className="flex flex-col  my-10 justify-center items-center">
                  <div className="bg-faintbg p-10 rounded-lg">
                    <img className="w-12" src={hyperlocal} alt="" />
                  </div>
                  <br />
                  <p className="font-medium text-xl">Hyperlocal</p>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="bg-faintbg p-10 rounded-lg">
                    <img className="w-12" src={realtime} alt="" />
                  </div>
                  <br />
                  <p className="font-medium text-xl">Real Time Q&#38;A</p>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="bg-faintbg px-10 py-12 rounded-lg">
                    <img className="w-12" src={responsible} alt="" />
                  </div>
                  <br />
                  <p className="font-medium text-xl">Responsible</p>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="bg-faintbg p-10 rounded-lg">
                    <img className="w-12" src={anonymous} alt="" />
                  </div>
                  <br />
                  <p className="font-medium text-xl">Anonymous</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-darkbg">
            <div className="max-w-7xl my-0 mx-auto py-20 w-full">
              <h2 className="font-semibold text-center">
                Download{" "}
                <span className="text-violetaccent  font-semibold text-center">
                  MapZap{" "}
                </span>
                Now
              </h2>
              <center>
                <div className="flex-container">
                <a
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=com.laxman.mapzap&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1",
                      "_blank"
                    )
                  }
                >
                  <img
                    className="h-20 mx-auto"
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  />
                </a>
                <a
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/in/app/mapzap/id1672644719",
                      "_blank"
                    )
                  }
                >
                  <img
                    className="h-20 mx-auto app-store"
                    alt="Download on App store"
                    src={download}
                  />
                </a>
                </div>
              </center>
            </div>
          </div>
        </main>
        <footer className="bg-black py-7">
          <p className="text-center">All Rights Reserved © {year} MapZap. </p>
        </footer>
      </div>
    </div>
  );
}

export default Landing;
