import React from "react";
import './Terms.css'

const Terms = () => {
  return (

    <div className=" bg-darkfont text-whitefont">
        <div className="max-w-3xl m-auto pt-3 px-5">
      <h1>END USER LICENSE AGREEMENT</h1>
      <h4>Last updated January 24, 2023</h4>
      <br />
      <br />
      <p>
        MapZap is licensed to You (End-User) by MapZap Inc., located and
        registered at 5906 NE Skipton St, Hillsboro, Oregon97124, United States
        ("<b>Licensor</b>"), for use only under the terms of this License
        Agreement.
      </p>
      <br />
      <p>
        By downloading the Licensed Application from Apple's software
        distribution platform ("App Store") and Google's software distribution
        platform ("Play Store"), and any update thereto (as permitted by this
        License Agreement), You indicate that You agree to be bound by all of the
        terms and conditions of this License Agreement, and that You accept this
        License Agreement. App Store and Play Store arereferred to in this
        License Agreement as "<b>Services</b>."
      </p>
      <br />
      <p>
        The parties of this License Agreement acknowledge that the Services are
        not a Party to this License Agreement and are not bound by any provisions
        or obligations with regard to the Licensed Application, such as
        warranty, liability, maintenance and support thereof. MapZap Inc., not
        the Services, is solely responsible for the Licensed Application and the
        content thereof.
      </p>
      <br />
      <p>
        This License Agreement may not provide for usage rules for the Licensed
        Application that are in conflict with the latest{" "}
        <a href="https://www.apple.com/legal/internet-services/itunes/us/terms.html">
          Apple Media Services Terms and Conditions
        </a>{" "}
        and{" "}
        <a href="https://play.google.com/intl/en_US/about/play-terms/">
          Google Play Terms of Service
        </a>{" "}
        ("<b>Usage Rules</b>"). MapZap Inc. acknowledges that it had the
        opportunity to review the Usage Rules and this License Agreement is not
        conflicting with them.
      </p>
      <br />
      <p>
        MapZap when purchased or downloaded through the Services, is licensed to
        You for use only under the terms of this License Agreement. The Licensor
        reserves all rights not expressly granted to You. MapZap is to be used
        on devices that operate with Apple's operating systems ("iOS" and "Mac
        OS") or Google's operating system ("Android").
      </p>
      <br />
      <br />

      <h2>TABLE OF CONTENTS</h2>
      <br />
      <ol className="list-decimal">
        <li>
          <a href="#application">THE APPLICATION</a>
        </li>
        <li>
          <a href="#scope">SCOPE OF LICENSE</a>
        </li>
        <li>
          <a href="#requirements">TECHNICAL REQUIREMENTS</a>
        </li>
        <li>
          <a href="#nosupport">NO MAINTENANCE AND SUPPORT</a>
        </li>
        <li>
          <a href="#datause">USE OF DATA</a>
        </li>
        <li>
          <a href="#ugc">USER-GENERATED CONTRIBUTIONS</a>
        </li>
        <li>
          <a href="#contribution">CONTRIBUTION LICENSE</a>
        </li>
        <li>
          <a href="#liability">LIABILITY</a>
        </li>
        <li>
          <a href="#warranty">WARRANTY </a>
        </li>
        <li>
          <a href="#productclaims">PRODUCT CLAIMS</a>
        </li>
        <li>
          <a href="#compliance">LEGAL COMPLIANCE</a>
        </li>
        <li>
          <a href="#contact">CONTACT INFORMATION</a>
        </li>
        <li>
          <a href="#termination">TERMINATION</a>
        </li>
        <li>
          <a href="#thirdparty">
            THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY
          </a>
        </li>
        <li>
          <a href="#ipr">INTELLECTUAL PROPERTY RIGHTS</a>
        </li>
        <li>
          <a href="#law">APPLICABLE LAW</a>
        </li>
        <li>
          <a href="#misc">MISCELLANEOUS </a>
        </li>
      </ol>
      <br /><br />

      <div id="application">
        <h2>1. THE APPLICATION</h2>
        <p>
          MapZap ("<b>Licensed Application"</b>") is a piece of software created
          to Connect users to other users in their proximity to share
          time-sensitive and location-specific information — and customized for
          iOS and Androidmobile devices ("<b>Devices</b>"). It is used to
          Location specific, Anonymous Q&A and messaging.
        </p>
        <br />
        <p>
          The Licensed Application is not tailored to comply with
          industry-specific regulations (Health Insurance Portability and
          Accountability Act (HIPAA), Federal Information Security Management
          Act (FISMA), etc.), so if your interactions would be subjected to such
          laws, you may not use this Licensed Application. You may not use the
          Licensed Application in a way that would violate the
          Gramm-Leach-Bliley Act (GLBA).
        </p><br /><br />
      </div>
      <div id="scope">
        <h2>2. SCOPE OF LICENSE</h2>
        <p>
          2.1 You may not reverse engineer, translate, disassemble, integrate,
          decompile, remove, modify, combine, create derivative works or updates
          of, adapt, or attempt to derive the source code of the Licensed
          Application, or any part thereof (except with MapZap Inc.'s prior
          written consent).
        </p>
        <br />
        <p>
          2.2 You may not copy (excluding when expressly authorizedby this
          license and the Usage Rules) or alter the Licensed Application or
          portions thereof. You may create and store copies only on devices that
          You own or control for backup keeping under the terms of this license,
          the Usage Rules, and any other terms and conditions that apply to the
          device or software used. You may not remove any intellectual property
          notices. You acknowledge that no unauthorized third parties may gain
          access to these copies at any time. If you sell your Devices to a
          third party, you must remove the Licensed Application from the Devices
          before doing so.
        </p>
        <br />
        <p>
          2.3 Violations of the obligations mentioned above, as well as the
          attempt of such infringement, may be subject to prosecution and
          damages.
        </p>
        <br />
        <p>
          2.4 Licensor reserves the right to modify the terms and conditions of
          licensing.
        </p>
        <br />
        <p>
          2.5 Nothing in this licenseshould be interpreted to restrict
          third-party terms. When using the Licensed Application, You must
          ensure that You comply with applicable third-party terms and
          conditions.
        </p>
        <br />
        <br />
      </div>
      <div id="requirements">
        <h2>3. TECHNICAL REQUIREMENTS</h2>
        <p>
          3.1 Licensor attempts to keep the Licensed Application updated so that
          it complies with modified/new versions of the firmware and new
          hardware. You are not granted rights to claim such an update.
        </p>
        <br />
        <p>
          3.2 You acknowledge that it is Your responsibility to confirm and
          determine that the app end-user device on which You intend to use the
          Licensed Application satisfies the technical specifications mentioned
          above.
        </p>
        <br />
        <p>
          3.3 Licensor reserves the right to modify the technical specifications
          as it sees appropriate at any time.
        </p>
        <br />
        <br />
      </div>
      <div id="nosupport">
        <h2>4. NO MAINTENANCE AND SUPPORT</h2>
        <p>
          4.1 MapZap Inc. is not obligated, expressed or implied, to provide any
          maintenance, technical or other support for the Licensed Application.
        </p>{" "}
        <br />
        <p>
          4.2 MapZap Inc. and the End-User acknowledge that the Services have no
          obligation whatsoever to furnish any maintenance and support services
          with respect to the Licensed Application.
        </p>
        <br />
        <br />
      </div>
      <div id="datause">
        <h2>5. USE OF DATA</h2>
        <p>
          You acknowledge that Licensor will be able to access and adjust Your
          downloaded Licensed Application content and Your personal information,
          and that Licensor's use of such material and information is subject to
          Your legal agreements with Licensor and Licensor's privacy policy.
        </p>
        <br />
        <p>
          You acknowledge that the Licensor may periodically collect and use
          technical data and related information about your device, system, and
          application software, and peripherals, offer product support,
          facilitate the software updates, and for purposes of providing other
          services to you (if any) related to the Licensed Application. Licensor
          may also use this information to improve its products or to provide
          services or technologies to you, as long as it is in a form that does
          not personally identify you.
        </p>
        <br />
        <br />
      </div>
      <div id="ugc">
        <h2>6. USER-GENERATED CONTRIBUTIONS</h2>
        <p>
          The Licensed Application may invite you to chat, contribute to, or
          participate in blogs, message boards, online forums, and other
          functionality, and may provide you with the opportunity to create,
          submit, post, display, transmit, perform, publish, distribute, or
          broadcast content and materials to us or in the Licensed Application,
          including but not limited to text, writings, video, audio,
          photographs, graphics, comments, suggestions, or personal information
          or other material (collectively, "Contributions"). Contributions may
          be viewable by other users of the Licensed Application and through
          third-party websites or applications. As such, any Contributions you
          transmit may be treated as non-confidential and non-proprietary. When
          you create or make available any Contributions, you thereby represent
          and warrant that:
        </p>
        <br />
        <p className="ml-10">
          1. The creation, distribution, transmission, public display, or
          performance, and the accessing, downloading, or copying of your
          Contributions do not and will not infringe the proprietary rights,
          including but not limited to the copyright, patent, trademark, trade
          secret, or moral rights of any third party. <br /><br /> 2. You are the
          creator and owner of or have the necessary licenses, rights, consents,
          releases, and permissions to use and to authorizeus, the Licensed
          Application, and other users of the Licensed Application to use your
          Contributions in any manner contemplated by the Licensed Application
          and this License Agreement. <br /><br /> 3. You have the written consent,
          release, and/or permission of each and every identifiable individual
          person in your Contributions to use the name or likeness or each and
          every such identifiable individual person to enable inclusion and use
          of your Contributions in any manner contemplated by the Licensed
          Application and this License Agreement. <br /><br /> 4. Your Contributions
          are not false, inaccurate, or misleading. <br /><br /> 5. Your Contributions
          are not unsolicited or unauthorizedadvertising, promotional materials,
          pyramid schemes, chain letters, spam, mass mailings, or other forms of
          solicitation. <br /><br /> 6. Your Contributions are not obscene, lewd,
          lascivious, filthy, violent, harassing, libelous, slanderous, or
          otherwise objectionable (as determined by us). <br /><br /> 7. Your
          Contributions do not ridicule, mock, disparage, intimidate, or abuse
          anyone. <br /><br /> 8. Your Contributions are not used to harass or
          threaten (in the legal sense of those terms) any other person and to
          promote violence against a specific person or class of people. <br /><br />{" "}
          9. Your Contributions do not violate any applicable law, regulation,
          or rule. <br /><br /> 10. Your Contributions do not violate the privacy or
          publicity rights of any third party. <br /><br /> 11. Your Contributions do
          not violate any applicable law concerning child pornography, or
          otherwise intended to protect the health or well-being of minors.{" "}
          <br /><br /> 12. Your Contributions do not include any offensive comments
          that are connected to race, national origin, gender, sexual
          preference, or physical handicap. <br /><br /> 13. Your Contributions do not
          otherwise violate, or link to material that violates, any provision of
          this License Agreement, or any applicable law or regulation.
        </p>
        <br /><br />
        <p>
          Any use of the Licensed Application in violation of the foregoing
          violates this License Agreement and may result in, among other things,
          termination or suspension of your rights to use the Licensed
          Application.
        </p>
        <br />
        <br />
      </div>
      <div id="contribution">
        <h2>7. CONTRIBUTION LICENSE</h2>
        <p>
          By posting your Contributions to any part of the Licensed Application
          or making Contributions accessible to the Licensed Application by
          linking your account from the Licensed Application to any of your
          social networking accounts, you automatically grant, and you represent
          and warrant that you have the right to grant, to us an unrestricted,
          unlimited, irrevocable, perpetual, non-exclusive, transferable,
          royalty-free, fully-paid, worldwide right, and licenseto host, use
          copy, reproduce, disclose, sell, resell, publish, broad cast, retitle,
          archive, store, cache, publicly display, reformat, translate,
          transmit, excerpt (in whole or in part), and distribute such
          Contributions (including, without limitation, your image and voice)
          for any purpose, commercial advertising, or otherwise, and to prepare
          derivative works of, or incorporate in other works, such as
          Contributions, and grant and authorize sublicensesof the foregoing.
          The use and distribution may occur in any media formats and through
          any media channels.
        </p>
        <br />
        <p>
          This license will apply to any form, media, or technology now known or
          hereafter developed, and includes our use of your name, company name,
          and franchise name, as applicable, and any of the trademarks, service
          marks, trade names, logos, and personal and commercial images you
          provide. You waive all moral rights in your Contributions, and you
          warrant that moral rights have not otherwise been asserted in your
          Contributions.
        </p>
        <br />
        <p>
          We do not assert any ownership over your Contributions. You retain
          full ownership of all of your Contributions and any intellectual
          property rights or other proprietary rights associated with your
          Contributions. We are not liable for any statements or representations
          in your Contributions provided by you in any area in the Licensed
          Application. You are solely responsible for your Contributions to the
          Licensed Application and you expressly agree to exonerate us from any
          and all responsibility and to refrain from any legal action against us
          regarding your Contributions.
        </p>
        <br />
        <p>
          We have the right, in our sole and absolute discretion, (1) to edit,
          redact, or otherwise change any Contributions; (2) to recategorizeany
          Contributions to place them in more appropriate locations in the
          Licensed Application; and (3) to prescreen or delete any Contributions
          at any time and for any reason, without notice. We have no obligation
          to monitor your Contributions.
        </p>
        <br />
        <br />
      </div>
      <div id="liability">
        <h2>8. LIABILITY</h2>
        <p>
          8.1 Licensor takes no accountability or responsibility for any damages
          caused due to a breach of duties according to Section 2 of this
          License Agreement. To avoid data loss, You are required to make use of
          backup functions of the Licensed Application to the extent allowed by
          applicable third-party terms and conditions of use. You are aware that
          in case of alterations or manipulations of the Licensed Application,
          You will not have access to the Licensed Application.
        </p>
        <br />
        <p>
          8.2 Licensor takes no accountability and responsibility in case of
          Trolling, bullying, violence, stalking, sharing of personal data,
          adult content, political sensitivity.
        </p>
        <br />
        <br />
      </div>
      <div id="warranty">
        <h2>9. WARRANTY</h2>
        <p>
          9.1 Licensor warrants that the Licensed Application is free of
          spyware, trojan horses, viruses, or any other malware at the time of
          Your download. Licensor warrants that the Licensed Application works
          as described in the user documentation.
        </p>
        <br />
        <p>
          9.2 No warranty is provided for the Licensed Application that is not
          executable on the device, that has been unauthorizedlymodified,
          handled inappropriately or culpably, combined or installed with
          inappropriate hardware or software, used with inappropriate
          accessories, regardless if by Yourself or by third parties, or if
          there are any other reasons outside of MapZap Inc.'s sphere of
          influence that affect the executability of the Licensed Application.
        </p>
        <br />
        <p>
          9.3 You are required to inspect the Licensed Application immediately
          after installing it and notify MapZap Inc. about issues discovered
          without delay by email provided in{" "}
          <a href="contact">Contact Information </a>. The defect report will be
          taken into consideration and further investigated if it has been
          emailed within a period of sixty (60) days after discovery.
        </p>
        <br />
        <p>
          9.4 If we confirm that the Licensed Application is defective, MapZap
          Inc. reserves a choice to remedy the situation either by means of
          solving the defect or substitute delivery.
        </p>
        <br />
        <p>
          9.5 In the event of any failure of the Licensed Application to conform
          to any applicable warranty, You may notify the Services Store
          Operator, and Your Licensed Application purchase price will be
          refunded to You. To the maximum extent permitted by applicable law,
          the Services Store Operator will have no other warranty obligation
          whatsoever with respect to the Licensed Application, and any other
          losses, claims, damages, liabilities, expenses, and costs attributable
          to any negligence to adhere to any warranty.
        </p>
        <br />
        <p>
          9.6 If the user is an entrepreneur, any claim based on faults expires
          after a statutory period of limitation amounting to twelve (12) months
          after the Licensed Application was made available to the user. The
          statutory periods of limitation given by law apply for users who are
          consumers.
        </p>
        <br />
        <br />
      </div>
      <div id="productclaims">
        <h2>10. PRODUCT CLAIMS</h2>
        <p>
          MapZap Inc. and the End-User acknowledge that MapZap Inc., and not the
          Services, is responsible for addressing any claims of the End-User or
          any third party relating to the Licensed Application or the End-User’s
          possession and/or use of that Licensed Application, including, but not
          limited to:
        </p>
        <br />
        <div className="tabspace">
          <p>(i) product liability claims;</p>
          <br />
          <p>
            (ii) any claim that the Licensed Application fails to conform to any
            applicable legal or regulatory requirement; and
          </p>
          <br />
          <p>
            (iii) claims arising under consumer protection, privacy, or similar
            legislation, including in connection with Your Licensed
            Application’s use of the HealthKit and HomeKit.
          </p>
          <br />
          <br />
        </div>
      </div>
      <div id="compliance">
        <h2>11. LEGAL COMPLIANCE</h2>
        <p>
          You represent and warrant that You are not located in a country that
          is subject to a US Government embargo, or that has been designated by
          the US Government as a "terrorist supporting" country; and that You
          are not listed on any US Government list of prohibited or restricted
          parties.
        </p>
        <br />
        <br />
      </div>
      <div id="contact">
        <h2>12. CONTACT INFORMATION</h2>
        <p>
          For general inquiries, complaints, questions or claims concerning the
          Licensed Application, please contact:
        </p>
        <br />
        <p>Mandar Joshi</p>
        <p>5906 NE Skipton St</p>
        <p>Hillsboro, OR97124</p>
        <p>United States</p>
        <p>mandar@mapzap.app</p> <br />
        <br />
      </div>
      <div id="termination">
        <h2>13. TERMINATION</h2>
        <p>
          The licenseis valid until terminated by MapZap Inc. or by You. Your
          rights under this licensewill terminate automatically and without
          notice from MapZap Inc. if You fail to adhere to any term(s) of this
          license. Upon Licensetermination, You shall stop all use of the
          Licensed Application, and destroy all copies, full or partial, of the
          Licensed Application.
        </p>
        <br />
        <br />
      </div>
      <div id="thirdparty">
        <h2>14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</h2>
        <p>
          MapZap Inc. represents and warrants that MapZap Inc. will comply with
          applicable third-party terms of agreement when using Licensed
          Application.
        </p>
        <br />
        <p>
          In Accordance with Section 9 of the "Instructions for Minimum Terms of
          Developer's End-User License Agreement," both Apple and Google and
          theirsubsidiaries shall be third-party beneficiaries of this End User
          License Agreement and — upon Your acceptance of the terms and
          conditions of this License Agreement, both Apple and Googlewill have
          the right (and will be deemed to have accepted the right) to enforce
          this End User License Agreement against You as a third-party
          beneficiary thereof.
        </p>
        <br />
        <br />
      </div>
      <div id="ipr">
        <h2>15. INTELLECTUAL PROPERTY RIGHTS</h2>
        <p>
          MapZap Inc. and the End-User acknowledge that, in the event of any
          third-party claim that the Licensed Application or the End-User's
          possession and use of that Licensed Application infringes on the third
          party's intellectual property rights, MapZap Inc., and not the
          Services, will be solely responsible for the investigation, defense,
          settlement, and discharge or any such intellectual property
          infringement claims.
        </p>
        <br />
        <br />
      </div>
      <div id="law">
        <h2>16. APPLICABLE LAW</h2>
        <p>
          This License Agreement is governed by the laws of Indiaexcluding its
          conflicts of law rules.
        </p>
        <br />
        <br />
      </div>
      <div id="misc">
        <h2>17. MISCELLANEOUS</h2>
        <p>
          17.1 If any of the terms of this agreement should be or become
          invalid, the validity of the remaining provisions shall not be
          affected. Invalid terms will be replaced by valid ones formulated in a
          way that will achieve the primary purpose.
        </p>
        <br />
        <p>
          17.2 Collateral agreements, changes and amendments are only valid if
          laid down in writing. The preceding clause can only be waived in
          writing.
        </p>
        <br />
        <br />
      </div>
    </div>
    </div>
  );
};

export default Terms;
