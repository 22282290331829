import React from 'react';
import { useHistory } from "react-router-dom";

function DeleteUser() {
    
    const history = useHistory();
    function deleteAccount() {
        history.push('/')
    }

    return (
        <div className="bg-darkbg h-screen flex text-subtlegrey">
            <div className="w-full">
                <h2 className="font-medium px-6 py-10">Request to delete user</h2>
                <div className="h-auto  flex flex-col gap-2   w-11/12 sm:w-2/3 md:w-1/2 mx-auto mt-10 rounded-2xl">
              
                <form className="px-5 py-16 w-full md:w-9/12 mx-auto" >
                    <div class="w-full px-3 py-3">
                        <label class="block   text-subtlegrey text-sm font-bold mb-2" for="grid-last-name">
                            Phone No
                        </label>
                        <input required class="appearance-none block w-full bg-bluelight text-subtlegrey   rounded py-1 px-4 leading-tight focus:outline-none focus:bg-bluemid " id="grid-last-name" type="tel"  />
                    </div>
                    <div class="w-full px-3 py-1">
                        <label class="block   text-subtlegrey text-sm font-bold mb-2" for="grid-description">
                            Reason (Optional)
                        </label>
                        <textarea class="appearance-none block w-full bg-bluelight text-subtlegrey   rounded py-1 px-4 leading-tight focus:outline-none focus:bg-bluemid " id="grid-description" rows="8" />
                    </div>
                
                    
                    <button type='submit' className="bg-red-700 text-white px-10 mt-5 py-1 rounded-2xl" onSubmit={(e) => deleteAccount()}>Delete Account</button>
                </form>
                </div>
            </div>
            
        </div>
    )
}

export default DeleteUser
