import React, {useState} from 'react'
import Sidebar from '../../components/Sidebar'
import { useHistory } from 'react-router-dom'

function Register() {
    const history = useHistory()

    const [username, setusername] = useState("")
    const [email, setemail] = useState("")
    const [pass, setpass] = useState("")
    const [confirmPass, setconfirmPass] = useState("")

    function validate(user, email, pass, cpass) {
        let error;
        if(user === "" || email === "" || pass === "" || cpass) {
            error = "fill out all fields"
        } else if (pass !== cpass) {
            error = "passwords should match"
        } else if (user < 8) {
            error = "username should be greater than 8"
        }
        // } else if(passvalidate(pass)) {
        //     error = "password should contain letters, special chars and digits"
        // } else if(emailvalidate(email)) {
        //     error = "invalid email, please check again"
        // } 
        else {
            error = ""
        }

        return error
    }

    function onNext(e) {
        e.preventDefault();

        const payload = {
            email : email,
            password : pass,
            password2 : confirmPass,
            user_name : username
        }
        
        console.log("inside")
        fetch('http://127.0.0.1:8080/api/register/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        }).then(response => {
            console.log(response)
        }).catch(err => {
            console.log(err)
        })
       
    }
    return (
        <div className="flex justify-between">
            <div className="my-5 w-1/2 m-auto max-w-lg">
                <h3 className="text-2xl font-medium font-poppins"  >Let's set up your shop</h3>
                <form className="my-14" action="" onSubmit={(e) => onNext(e)}>
                    
                        <label htmlFor="" className="block mt-5 text-tbtext">
                            Username*
                        </label>
                        <input   className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full my-1 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purpleaccent" value={username} onChange={(e) => setusername(e.target.value)}  type="text" name="username" id="" required/>
                        <label htmlFor="" className="block mt-5 text-tbtext">
                            Business Email Address *
                        </label>
                        <input   className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full my-1 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purpleaccent" value={email} onChange={(e) => setemail(e.target.value)}   type="text" name="" id=""  required/>

                        <label htmlFor="" className="block mt-5 text-tbtext">
                            Password *
                        </label>
                        <input   className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full my-1 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purpleaccent" value={pass} onChange={(e) => setpass(e.target.value)}   type="text" name="" id=""  required/>
                    
                        <label htmlFor="" className="block mt-5 text-tbtext">
                            Confirm Password *
                        </label>
                        <input   className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full my-1 py-1 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purpleaccent" value={confirmPass} onChange={(e) => setconfirmPass(e.target.value)}   type="text" name="" id=""  required/>
                    
                    
                    

                    <button type="submit" className="bg-purpleaccent text-white px-10 py-1 rounded-2xl mt-5" >Register</button>

                </form>
            </div>
           <Sidebar />
        </div>
    )
}

export default Register

