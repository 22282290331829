import React, {useState, useEffect} from 'react'
import SideNav from '../components/SideNav'

import Select from "react-dropdown-select";
function Form() {
    const options = [
        {label:"Admin", value: 1},
        {label:"Read Only", value: 2},
    ];
    const [values, setValues] = useState([])
    return (
        <div className="bg-darkbg h-screen flex text-subtlegrey">
            <SideNav />
            <div className="w-full">
                <h2 className="font-medium px-6 py-10">Contact us</h2>
                <div className="newaddiv h-auto  flex flex-col gap-2   w-11/12 sm:w-2/3 md:w-1/2 mx-auto mt-10 rounded-2xl">
               
               
              
                <form className="px-5 py-16 w-full md:w-9/12 mx-auto" >
                    <div class="w-full px-3 py-3">
                        <label class="block   text-subtlegrey text-sm font-bold mb-2" for="grid-last-name">
                            Subject
                        </label>
                        <input class="appearance-none block w-full bg-bluelight text-subtlegrey   rounded py-1 px-4 leading-tight focus:outline-none focus:bg-bluemid " id="grid-last-name" type="text"  />
                    </div>
                    <div class="w-full px-3 py-1">
                        <label class="block   text-subtlegrey text-sm font-bold mb-2" for="grid-description">
                            Short Description
                        </label>
                        <textarea class="appearance-none block w-full bg-bluelight text-subtlegrey   rounded py-1 px-4 leading-tight focus:outline-none focus:bg-bluemid " id="grid-description" rows="8" />
                    </div>
                
                    
                    <button className="bg-purpleaccent text-white px-10 mt-5 py-1 rounded-2xl">Contact</button>
                </form>
                </div>
            </div>
            
        </div>
    )
}

export default Form
